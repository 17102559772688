import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="ticket-listing">
      <Helmet>
        <title>{t(`TICKETLISTING.TITLE`)}</title>
        <body className="ticket-listing-page inverse-navbar" />
      </Helmet>
      <Container fluid className="header-image" />
      <Container className="list-page my-5 p-0">
        <Container className="list-page py-5">
          {loading ? ( // if loading
            <LoadingSpinner label={t(`LOADING.TICKETS`)} />
          ) : tickets.length === 0 ? ( // if no tickets
            <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
          ) : (
            // else show tickets
            <Masonry
              id="ticket-listing"
              products={tickets}
              type="list"
              filters={['fulltext']}
              showTitle
              showCity
              showLocation
              showTags
            />
          )}
        </Container>

        <ContentrArea id="after-ticket-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
